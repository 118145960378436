<template>
  <b-card>
    <div v-if="visible" class="custom-container">
      <form @submit.prevent="TerminarEvaluacion()">
        <div class="p-2">
          <!-- p1 -->
          <div class="row justify-content-center">
            <div class="col-12 col-lg-8 mt-0">
              <card class="bg-dark p-3">
                <div class="row">
                  <div
                    class="col-12 col-md-2 d-flex align-items-center justify-content-center"
                  >
                    <vs-avatar circle primary size="70">
                      <i
                        class="fas fa-file-lines fa-fade"
                        style="font-size: 3rem"
                      ></i>
                    </vs-avatar>
                  </div>
                  <div class="col">
                    <h3 style="font-weight: 800">EVALUACIÓN ACTIVA</h3>
                    <!-- info -->
                    <div class="d-flex mt-2" style="gap: 1.5rem">
                      <!-- nombre -->
                      <div>
                        <span class="text-white-50 font-italic">Nombre</span>
                        <h5>
                          {{ CicloEvaluacionUsuario.cicloEvaluacion?.nombre }}
                        </h5>
                      </div>
                      <!-- evaluado -->
                      <div>
                        <span class="text-white-50 font-italic">Evaluado</span>
                        <h5>
                          {{
                            personaEvaluada.nombres +
                            " " +
                            personaEvaluada.apellidos
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>

          <!-- p2 -->

          <!-- {{ CicloEvaluacionUsuario }}<br /> -->
          <!-- {{ personaEvaluada }} -->

          <div class="mt-3">
            <div class="row justify-content-center">
              <!-- preguntas -->
              <div
                class="col-12 col-lg-8"
                v-for="(PreguntaItem, contPregunta) of PreguntaList"
                v-bind:key="PreguntaItem.id"
              >
                <card class="bg-dark">
                  <div class="card-body">
                    <div class="" v-if="PreguntaItem.preguntaControlActiva">
                      <h3 class="card-title">
                        <b>{{
                          "Pregunta de control: " + PreguntaItem.preguntaControl
                        }}</b
                        ><br />
                        <div class="row pt-2">
                          <div class="col col-lg-2">
                            <span class="mr-4">Si</span>
                            <input
                              v-on:click="
                                actualizaPreguntaControl(
                                  contPregunta,
                                  true,
                                  PreguntaItem
                                )
                              "
                              class="form-check-input"
                              style="transform: scale(2)"
                              type="radio"
                              :name="'Resp_' + contPregunta"
                              id="Resp_@ContPregunta@Contador"
                              required
                            />
                          </div>
                          <div class="col col-lg-2">
                            <span class="mr-4">No</span>
                            <input
                              required
                              v-on:click="
                                actualizaPreguntaControl(
                                  contPregunta,
                                  false,
                                  PreguntaItem
                                )
                              "
                              class="form-check-input"
                              style="transform: scale(2)"
                              type="radio"
                              :name="'Resp_' + contPregunta"
                              id="Resp_@ContPregunta@Contador"
                            />
                          </div>
                        </div>
                      </h3>
                    </div>

                    <div
                      v-if="
                        PreguntaItem.preguntaControlActiva == null ||
                        PreguntaItem.preguntaControlRespuesta == true
                      "
                      :key="PreguntaList[contPregunta].preguntaControlRespuesta"
                    >
                      <h3 class="card-title">
                        <b>{{ PreguntaItem.competencia.titulo }}</b
                        ><br />
                      </h3>
                      <p class="h4 text-white-50 font-italic">
                        {{ PreguntaItem.competencia.descripcion }}
                      </p>
                      <div class="table-responsive">
                        <!-- table -->
                        <vs-table striped>
                          <!-- thead -->
                          <template #thead>
                            <vs-tr>
                              <vs-th>N°</vs-th>
                              <vs-th
                                style="width: 100% !important; min-width: 200px"
                              >
                                Item de Evaluación
                              </vs-th>
                              <template v-for="Respuesta in RespuestaList">
                                <vs-th
                                  :key="Respuesta.id"
                                  class="text-center"
                                  v-if="
                                    Respuesta.pregunta.id == PreguntaItem.id
                                  "
                                  :style="
                                    'display:' + Respuesta.pregunta.id ==
                                    PreguntaItem.id
                                      ? 'block'
                                      : 'none'
                                  "
                                >
                                  <div
                                    v-if="
                                      Respuesta.pregunta.id == PreguntaItem.id
                                    "
                                  >
                                    {{ Respuesta.descripcion }}
                                  </div>
                                </vs-th>
                              </template>
                            </vs-tr>
                          </template>
                          <!-- tbody -->
                          <template #tbody>
                            <template v-for="(Item, Contador) in ItemList">
                              <vs-tr
                                v-if="Item.pregunta.id == PreguntaItem.id"
                                :key="Item.id"
                              >
                                <!-- <td>{{ Contador > Item.length ? Contador == 1 :  Contador + 1 }}</td> -->
                                <vs-td>-</vs-td>
                                <vs-td
                                  style="font-size: 1rem; line-height: 1.5"
                                  >{{ Item.descripcion }}</vs-td
                                >
                                <template v-for="Respuesta in RespuestaList">
                                  <vs-td
                                    class="text-center"
                                    v-if="
                                      Respuesta.pregunta.id == PreguntaItem.id
                                    "
                                    :style="
                                      'display:' + Respuesta.pregunta.id ==
                                      PreguntaItem.id
                                        ? 'inline'
                                        : 'none'
                                    "
                                    :key="Respuesta.id"
                                  >
                                    <input
                                      @click="ChangeResp(Item.id, Respuesta.id)"
                                      class="form-check-input"
                                      style="transform: scale(2)"
                                      type="radio"
                                      :name="'Resp_' + contPregunta + Contador"
                                      id="Resp_@ContPregunta@Contador"
                                      required
                                    />
                                  </vs-td>
                                </template>
                              </vs-tr>
                            </template>
                          </template>
                        </vs-table>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
              <!-- end col -->

              <!-- <div v-if="PreguntaItem.tipoPregunta.descripcion == 'Si/No'" >

                </div> 
                 <span class="col-12 col-form-label bold" style="font-size: 15px">1.1 - I - Acontecimiento traumático severo (NOM035-GR1-TODOS)</span>
                <span class="col-12 col-form-label" style="font-size: 15px">¿Ha presenciado o sufrido alguna vez, durante o con motivo del trabajo un acontecimiento como los siguientes: Marque SI o NO.</span> -->

              <!-- comments -->
              <div class="col-12 col-lg-8">
                <card class="bg-dark content-comments">
                  <div class="p-3">
                    <div class="d-flex align-items-center" style="gap: 0.5rem">
                      <i class="fas fa-comments" style="font-size: 2rem"></i>
                      <h3 class="card-title" style="font-weight: 700">
                        Comentarios Generales
                      </h3>
                    </div>
                    <p class="text-white-50 h4 font-italic">
                      En este apartado puede realizar comentarios y sugerencias;
                      Esta información será confidencial.
                    </p>
                  </div>
                  <div class="card-body py-2">
                    <quill-editor
                      style="height: 100px"
                      :options="state.editorOption"
                      @change="onEditorChange($event)"
                    ></quill-editor>
                  </div>
                </card>
              </div>
            </div>
            <!-- <div class="row">
                <label class="col-xl-4 col-form-label">:</label>
                <div class="col-xl-8">
                </div>
              </div> -->
          </div>
          <!-- btns -->
          <div class="row justify-content-center">
            <div
              class="col-12 col-lg-8 d-flex align-items-center justify-content-end"
            >
              <vs-button type="submit" class="p-1"  >
                <i class="fas fa-circle-check mr-1"></i>
                Terminar Evaluación
              </vs-button>
              <vs-button class="p-1" to="/helex/pendientes" danger  
                >Cancelar Evaluación</vs-button
              >

              <!-- <input class="btn btn-blue" type="submit" value="Guardar datos" /> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </b-card>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import moment_timezone from "moment-timezone";

import Swal from "sweetalert2";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      tableName: "persona",
      documento: "",
      id: "",
      cicloEvaluacionUsuarioId: "",
      visible: true,
      row: {},
      //////////
      CicloEvaluacionUsuario: {},
      personaEvaluada: { nombres: "", apellidos: "" },
      PreguntaList: [],
      competenciaPreguntas: [],
      RespuestaList: [],
      ItemList: [],
      cuenta: 1,
      comentario: "",
      state: {
        content: "<p>2333</p>",
        _content: "",
        editorOption: {
          placeholder: "",
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              // [{ script: 'sub' }, { script: 'super' }],
              // [{ indent: '-1' }, { indent: '+1' }],
              // [{ direction: 'rtl' }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              ["link", "image"],
            ],
          },
        },
        disabled: false,
      },
    };
  },
  async mounted() {
    this.$isLoading(true);

    try {
      this.id = this.$route.params.id;
      this.cicloEvaluacionUsuarioId = this.$route.params.ciclo;
      //await this.getCicloEvaluacion();

      this.row = JSON.parse(localStorage.getItem("setPersonaAct"));
      console.log("--------->1");
      await this.getCicloEvaluacion(); //ok
      console.log("--------->2");
      this.PreguntaList = await this.ListPreguntasByCompetencias();
      for await (let itmm of this.PreguntaList) {
        itmm.preguntaControlRespuesta = false;
      }

      //
      //intentar ordenar lista
      this.PreguntaList.sort(
        (a, b) =>
          b.competencia.titulo.substring(0, 5) -
          a.competencia.titulo.substring(0, 5)
      );
      console.log(
        "***************** importante lista preguntas --------->3",
        this.PreguntaList
      );
      //
      for await (let ress of this.PreguntaList) {
        console.log("------", ress.competencia.titulo.substring(0, 5));
        console.log("------", ress.competencia.titulo);
      }
      this.ItemList = await this.ListPreguntaDetalleByPregunta(
        this.PreguntaList
      );
      console.log("--------->4");
      this.RespuestaList = await this.ListRespuestaByPregunta();
      console.log("--------->5", this.RespuestaList);
      this.personaEvaluada = await this.getPersona(
        this.CicloEvaluacionUsuario.perPrincipal
      );

      if (this.CicloEvaluacionUsuario.estado == 0) {
        Swal.fire({
          title: `Evaluación ya fue contestada anteriormente. Puedes cerrar esta pestaña.`,
        });
      } else {
        this.visible = true;
      }
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);

      console.log("error", error);
    }
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.comentario = html;
    },
    async actualizaPreguntaControl(item, valor, PreguntaItem) {
      let data = this.PreguntaList[item];
      this.PreguntaList[item].preguntaControlRespuesta = valor;
      console.log(valor);
      console.log("Preguntaitem------->", PreguntaItem);

      for await (let itres of this.RespuestaList) {
        if (itres.pregunta.id == PreguntaItem.id)
          console.log("itres - ", itres);
      }

      // console.log(id);
      console.log(data);
      let dataItem = this.ItemList.filter((x) => x.pregunta.id == data.id);
      //si valor es false guardar respuesta = 000-000 etc
      if (valor == false) {
        for await (let dtr of dataItem) {
          console.log("en la iteracción...", dtr);
          //el valor 00000 hace qe no funcione por
          //this.ChangeResp(dtr.id ,"00000000-0000-0000-0000-000000000000")
        }
      }
      // console.log('dataitem-->', dataItem)
      // console.log('dataitem-->', this.ItemList)
      this.$forceUpdate();
      // for await(let itRes of dataItem){
      //   let itt = this.ItemList.indexOf(itRes)
      //   console.log('itemlist ',itt)

      // }
    },
    async UpdateCicloEvaluacion() {
      let dataEv = {
        Id: this.cicloEvaluacionUsuarioId,
        FechaTerminado: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      let res = await this.$store.dispatch("hl_post", {
        path: "CicloEvaluacionUsuario/CambiarEstado/",
        data: dataEv,
      });
      return res;
    },
    async TerminarEvaluacion() {
      try {
        let CicloUpdate = await this.UpdateCicloEvaluacion();
        console.log("terminar evaluacion ---->", CicloUpdate);
        // console.log("terminar evaluacion ---->", CicloUpdate);
        console.log("this.comentario ---->", this.comentario);
        if (CicloUpdate == true) {
          if (this.comentario != null && this.comentario != "") {
            let CreateComentario = {
              Id: "00000000-0000-0000-0000-000000000000",
              Comentario: this.comentario,
              CicloEvaluacionId: this.CicloEvaluacionUsuario.cicloEvaluacion.id,
              EscritorId: this.id,
              EmpresaId: this.row.empresa.id,
              PersonaId: this.CicloEvaluacionUsuario.perPrincipal,
              FechaCreacion: moment_timezone(new Date()).format(
                "YYYY-MM-DDTHH:mm:ssZ"
              ),
            };
            console.log(CreateComentario);
            await this.postCreateComentario(CreateComentario);
          }
          this.visible = false;
          Swal.fire({
            title: `¡Evaluación almacenada correctamente!`,
          });
          this.$router.push("/helex/pendientes");
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async postCreateComentario(data) {
      let res = await this.$store.dispatch("hl_post", {
        path: "Comentario/CreateComentarioV2",
        data: data,
      });
      console.log(res);
    },
    async ChangeResp(pregunta, respuesta) {
      console.log(pregunta, " ", respuesta);
      let data = {
        PersonaId: this.id,
        CicloEvaluacionUsuarioId: this.cicloEvaluacionUsuarioId,
        PreguntaDetalleMovimientoId: respuesta,
        PreguntaDetalleId: pregunta,
      };
      console.log("datatosave ", data);
      let res = await this.$store.dispatch("hl_post", {
        path: "CicloEvaluacionUsuarioDetalle/GuardarRespuestasCicloEvaluacionUsuario",
        data: data,
      });
      console.log(res);
      //GuardarRespuestasCicloEvaluacionUsuario
    },
    async getCicloEvaluacion() {
      console.log("get cicloev....", this.cicloEvaluacionUsuarioId);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CicloEvaluacionUsuario/Get/" + this.cicloEvaluacionUsuarioId,
        });
        this.CicloEvaluacionUsuario = res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async ListPreguntasByCompetencias() {
      let CompetenciaList = await this.ListCompetenciaByCiclo();
      console.log("competencias ", CompetenciaList);
      return await this.getPreguntaByCompetencia(CompetenciaList);
    },
    async ListCompetenciaByCiclo() {
      try {
        let NewListCompetencia = [];
        if (this.CicloEvaluacionUsuario.cicloEvaluacion.tipoCompetencia == 0) {
          console.log(
            "tipo competencia == 0",
            this.CicloEvaluacionUsuario.perPrincipal
          );
          let persona = await this.getPersona(
            this.CicloEvaluacionUsuario.perPrincipal
          );
          let ubicacion = await this.$store.dispatch("hl_get", {
            path: "PersonaUbicacion/GetByPersona/" + persona.id,
          });
          let CargoCompt = await this.$store.dispatch("hl_get", {
            path: "CargoCompetencia/ListByCargo/" + ubicacion.cargo.id,
          });
          let CicloCompt = await this.$store.dispatch("hl_get", {
            path:
              "CicloEvaluacionCompetencia/ListCompetenciaByCiclo/" +
              this.CicloEvaluacionUsuario.cicloEvaluacion.id,
          });
          console.log("--->ca1", CargoCompt);
          console.log("--->ca2", CicloCompt);
          for await (let CCargo of CargoCompt) {
            for await (let CCiclo of CicloCompt) {
              if (CCargo.competencia.id == CCiclo.competencia.id) {
                var check = NewListCompetencia.filter(
                  (X) => X.competencia.id == CCiclo.competencia.id
                );
                console.log("es correcto...- check", check);
                if (check.length == 0) {
                  console.log("es null");
                  NewListCompetencia.push(CCiclo);
                }
              }
            }
          }
          return NewListCompetencia;
          //console.log("NewListCompetencia ", NewListCompetencia);
          //await this.ListPreguntaDetalleByPregunta();
        } else {
          console.log("tipo competencia > 0", this.cicloEvaluacionUsuarioId);
          let res = await this.$store.dispatch("hl_get", {
            path:
              "CicloEvaluacionCompetencia/ListCompetenciaByCiclo/" +
              this.CicloEvaluacionUsuario.cicloEvaluacion.id,
          });
          return res;
          // if (res.length > 0) {
          //   await this.ListPreguntaDetalleByPregunta();
          //   // PreguntasList = CompetenciaPreguntaService.ListPreguntaByCompetencia(CompetenciaList)
          //   //   .OrderBy((x) => x.Competencia.Titulo)
          //   //   .ToList();
          // } else {
          //   return null;
          // }
        }
      } catch (error) {
        console.log("error", error);
        return false;
      }
    },
    async getPreguntaByCompetencia(CompetenciaList) {
      try {
        console.log("a pasar...-.-.-", this.PreguntaList);
        let res = await this.$store.dispatch("hl_post", {
          path: "CompetenciaPregunta/ListPreguntaByCompetencia",
          data: CompetenciaList,
        });
        console.log(
          "a filtrar..... de aqui sale this.preguntaList ------------------",
          res
        );

        //await this.ListRespuestaByPregunta();
        // console.log("filtrando.....ok sin nada", this.PreguntaList);
        return res; //CompetenciaPreguntaService.ListPreguntaByCompetencia(CompetenciaList)
      } catch (error) {
        console.log("error", error);
        return null;
      }
    },
    async ListPreguntaDetalleByPregunta(PreguntaList) {
      try {
        console.log("ListPreguntaDetalleByPregunta", PreguntaList);
        let res = await this.$store.dispatch("hl_post", {
          path: "PreguntaDetalle/ListPreguntaDetalleByPregunta",
          data: PreguntaList,
        });
        console.log(
          "ItemList-------------------------------------------------------------",
          this.ItemList
        );
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async ListRespuestaByPregunta() {
      try {
        console.log("ListRespuestaByPregunta");
        let res = await this.$store.dispatch("hl_post", {
          path: "PreguntaDetalleMovimiento/ListRespuestaByPregunta",
          data: this.PreguntaList,
        });
        console.log("ListRespuestaByPregunta *****", this.RespuestaList);
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },

    ///////////////

    async getPersona(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersona/" + personaId,
        });
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },

    /////////////////////////
    async saveData() {
      // let row = { ...this.row };
      // delete row.nombreCargo;
      // let res = await this.$store.dispatch("post", { path: "PersonaFichaDatosRPS", data: row });
      // if (res) {
      //   Swal.fire({
      //     title: `Datos almacenados correctamente!`,
      //   });
      //   //console.log("res ", res);
      //   this.row = {};
      //   this.visible = false;
      //   location.reload();
      // }
    },
    async verifyUser(documento) {
      try {
        console.log("1");
        const data = {
          Id: this.id,
          NumeroDocumento: documento,
        };
        let res = await this.$store.dispatch("hl_loginByDocument", {
          path: "",
          data: data,
        });
        let ress = res;
        console.log("2");
        console.log("ya en el serve verify user length ", ress);
        if (res.id) {
          this.row = res;
          console.log(
            "********************/////////////////////////*********************",
            res
          );
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log("err", error);
        return false;
      }
    },
  },
};
</script>

<style scoped>
.content-comments {
  height: 450px;
}
@media (min-width: 768px) {
  .content-comments {
    height: 300px;
  }
}
.btn-blue {
  background-color: #556ee6;
  color: white !important;
}

label {
  text-transform: lowercase !important;
}

label::first-letter {
  text-transform: uppercase;
}
.bold {
  font-weight: bold !important;
}
.form-check-input {
  position: inherit;
  margin-left: auto;
  /* ///////////////////////////////////// */
}

/* the basic, unchecked style */
[type="radio"] + span:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.25em;
  border-radius: 1em; /*hard border*/
  border: 0.125em solid #fff;
  box-shadow: 0 0 0 0.15em #000; /*light shadow*/
  margin-right: 0.75em;
  transition: 0.5s ease all; /*animation here*/
}

/* the checked style using the :checked pseudo class */
[type="radio"]:checked + span:before {
  background: #df0c0c;
  box-shadow: 0 0 0 0.25em #000;
}
</style>
